import {WIX_ECOM} from '@wix/app-definition-ids';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EditorSDK} from '@wix/platform-editor-sdk';

export const createLightbox = async (sdk: EditorSDK, managingAppDefId: string) => {
  const CART_WIDGET_ID = '49dbb2d9-d9e5-4605-a147-e926605bf164';
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;

  const componentDefinition = {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: {
      width: 320,
      height: 815,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    data: {
      type: 'TPAMultiSection',
      applicationId: tpaApplicationId.toString(),
      appDefinitionId: WIX_ECOM,
      widgetId: CART_WIDGET_ID,
    },
  };

  const options = {
    title: 'Side Cart',
    hideTitle: true,
    shouldNavigateToPage: false,
    definition: {
      data: {
        tpaApplicationId,
        tpaPageId: PageMap.SIDE_CART,
        managingAppDefId,
        appDefinitionId: WIX_ECOM,
        pageBackgrounds: {
          desktop: {
            custom: true,
            ref: {
              type: 'BackgroundMedia',
              color: '#000',
              colorOpacity: 0.3,
              alignType: 'top',
              fittingType: 'fill',
              scrollType: 'fixed',
            },
            isPreset: true,
          },
          mobile: {
            custom: true,
            ref: {
              type: 'BackgroundMedia',
              color: '#000',
              colorOpacity: 0.3,
              alignType: 'top',
              fittingType: 'fill',
              scrollType: 'fixed',
            },
            isPreset: true,
          },
        },
      },
      components: [
        {
          type: 'Container',
          components: [componentDefinition],
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            width: 320,
            x: 682,
            y: 0,
          },
          effects: {
            type: 'EffectsList',
            values: [
              {
                type: 'TimeAnimation',
                name: 'SlideIn',
                value: {
                  default: {
                    delay: 0,
                    duration: 1200,
                    iterations: 1,
                    fill: 'backwards',
                    allowReplay: 'perPageView',
                    namedEffect: {
                      direction: 'right',
                      power: 'hard',
                      type: 'SlideIn',
                    },
                    type: 'TimeAnimationOptions',
                  },
                },
              },
            ],
          },
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: {
            type: 'PopupContainerProperties',
            alignmentType: 'fullHeight',
            horizontalAlignment: 'right',
            verticalAlignment: 'top',
            horizontalOffset: 0,
            verticalOffset: 0,
          },
        },
      ],
    },
  } as any;

  const sideCartRef = await sdk.pages.popupPages.add('', options);
  await (sdk.pages.popupPages as any).updateBehavior('', {
    popupRef: sideCartRef,
    shouldNavigateToPage: false,
    behavior: {
      name: 'openPopup',
      params: {
        delay: 0,
        openInDesktop: false,
        openInMobile: false,
      },
    },
  });

  return sideCartRef;
};
